import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react';

// Define PostHog options with proper TypeScript types
interface PostHogOptions {
  api_host: string | undefined;
  cookie_domain?: string;
  persistence?: 'localStorage' | 'cookie' | 'memory';
  autocapture?: boolean;
}

// Define PostHog options
const options: PostHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  cookie_domain: window.location.hostname, // This will fix the cookie domain issue
  autocapture: true,
};

// Type assertion for getElementById since we know 'root' exists
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY!} // Add ! to assert the value exists
      options={options}
    >
      <App />
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();