import React, { useEffect, useState, useRef } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { StepStatus } from '@/utils/Interfaces';
import { useSearchParams, useLocation } from 'react-router-dom';

interface PreviewData {
  businessName: string;
  adHeadings: string[];
  adDescriptions: string[];
  keywords: string[];
}

interface GoogleAdPreviewProps {
  url?: string;
  onStepComplete?: (status: StepStatus) => void;
}

const GoogleAdPreview: React.FC<GoogleAdPreviewProps> = ({ url, onStepComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();

  const previewRef = useRef<HTMLDivElement>(null);
  const optimizationRef = useRef<HTMLDivElement>(null);
  const getStartedRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const buffer = 100; // Buffer zone near the bottom

      // Get the positions of each section
      const previewPosition = previewRef.current?.offsetTop || 0;
      const optimizationPosition = optimizationRef.current?.offsetTop || 0;
      const getStartedPosition = getStartedRef.current?.offsetTop || 0;
      
      // Get the total scrollable height
      const documentHeight = document.documentElement.scrollHeight;
      const isNearBottom = documentHeight - scrollPosition < buffer;

      // Determine which section is currently in view
      if (isNearBottom || scrollPosition >= getStartedPosition) {
        setActiveStep(3);
      } else if (scrollPosition >= optimizationPosition) {
        setActiveStep(2);
      } else {
        setActiveStep(1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    onStepComplete?.({
      isComplete: !!previewData,
      message: !previewData && location.pathname.endsWith('?step=0') ? 'Please wait while we analyze your website' : undefined
    });
  }, [previewData, onStepComplete]);

  const goBackOneStep = () => {
    const currentStep = Number(searchParams.get('onboardingStep')) || 1;
    setSearchParams({ onboardingStep: (currentStep - 1).toString() });
  };

  const fetchPreviewData = async () => {
    if (!url) {
      toast.error('No URL provided');
      setIsLoading(false);
      goBackOneStep();
      return;
    }

    try {
      const data = await axios.get(`${baseUrl}/getSiteData`, {
        params: { url },
        headers: {
          'accept': 'application/json'
        }
      });

      const { descriptions, headlines, keywords, businessName, suggested_budget, domain } = data.data;
      setPreviewData({
        businessName,
        adHeadings: headlines,
        adDescriptions: descriptions,
        keywords: keywords,
      });

      localStorage.setItem(`${domain}_BUDGET_SUGGESTION`, suggested_budget);
      localStorage.setItem('domain', url);
    } catch (error) {
      toast.error(`Error analyzing website. Please try again.`);
      console.error('Error fetching site data:', error);
      goBackOneStep();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const stepVal = searchParams.get('step')
    if (url && stepVal && Number(stepVal) === 0) {
      setIsLoading(true);
      fetchPreviewData();
    }
  }, [url]);

  const handlePrevAd = () => {
    setCurrentAdIndex((prev) => 
      prev > 0 ? prev - 1 : (previewData?.adHeadings.length || 1) - 1
    );
  };

  const handleNextAd = () => {
    setCurrentAdIndex((prev) => 
      prev < (previewData?.adHeadings.length || 1) - 1 ? prev + 1 : 0
    );
  };

  if (isLoading || !previewData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex gap-12">
        {/* Main Content */}
        <div className="flex-1">
          <h1 className="text-2xl font-semibold mb-2">
            Welcome to Multiply, {previewData.businessName}
          </h1>
          <p className="text-gray-600 mb-8">
            Multiply simplifies your advertising journey by automatically creating, managing, and
            optimizing your Google Ad campaigns. Let's take a look at what Multiply will do for you.
          </p>
  
          {/* Preview Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={previewRef}>
                <h2 className="text-lg font-semibold mb-3">Preview your Google ads</h2>
                <p className="text-gray-600 mb-4">
                  Here's a sneak peek of an ad that Multiply might run for your business. Multiply is trained on millions 
                  of top-performing ad campaigns to generate high-quality ad creative that’s likely to get you new customers. 
                  You can always add, remove, or modify creative through your personal control dashboard.
                </p>
                
                <div className="mt-4">
                  <p className="text-sm text-gray-600 mb-2">Ex: Google Search Ad for {url}</p>
                  <motion.div
                    key={currentAdIndex}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p className="text-sm text-gray-600">Ad {url}</p>
                    <h3 className="text-blue-600 font-medium">
                      {previewData.adHeadings[currentAdIndex]}
                    </h3>
                    <p className="text-sm">
                      {previewData.adDescriptions[currentAdIndex]}
                    </p>
                  </motion.div>
                  <div className="flex items-center justify-between mt-4">
                    <p className="text-sm text-gray-500">
                      Ad Preview {currentAdIndex + 1} of {previewData.adHeadings.length}
                    </p>
                    <div className="flex gap-2">
                      <Button variant="outline" size="sm" onClick={handlePrevAd}>
                        <ChevronLeft className="h-4 w-4" />
                      </Button>
                      <Button variant="outline" size="sm" onClick={handleNextAd}>
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
  
          {/* Optimization Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={optimizationRef}>
                <h2 className="text-lg font-semibold mb-3">How Multiply optimizes your ads</h2>
                <p className="text-gray-600 mb-4">
                  Multiply uses advanced analytics and AI to write different keywords and messages for
                  your ads in order to maximize conversions.
                </p>
                
                <p className="text-sm text-gray-600 mb-2">Example keywords for your business</p>
                <div className="flex flex-wrap gap-2">
                  {previewData.keywords.map((keyword, index) => (
                    <span 
                      key={index}
                      className="px-3 py-1 bg-green-50 text-green-700 rounded-full text-sm"
                    >
                      {keyword}
                    </span>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
  
          {/* Get Started Section */}
          <Card className="mb-8">
            <CardContent className="pt-6">
              <div ref={getStartedRef}>
                <h2 className="text-lg font-semibold mb-3">Ready to get started</h2>
                <p className="text-gray-600 mb-4">
                  We'll help you reach more customers with automated, optimized ads.
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
  
        {/* Side Navigation */}
        <div className="w-64">
          <div className="sticky top-6 flex">
            {/* Vertical Line */}
            <div className="mr-3 relative">
              <div className="absolute left-3 top-3 bottom-3 w-0.5 bg-gray-200" />
              <div 
                className="absolute left-3 top-3 w-0.5 bg-green-500 transition-all duration-300"
                style={{
                  height: `${(activeStep / 3) * 100}%`
                }}
              />
            </div>
  
            {/* Navigation Items */}
            <div className="space-y-8">
              {[
                {
                  number: 1,
                  title: 'Ad previews',
                  description: 'Take a look at the ads Multiply will generate for your business.'
                },
                {
                  number: 2,
                  title: 'Optimizations',
                  description: 'Learn how Multiply will optimize your ads automatically.'
                },
                {
                  number: 3,
                  title: 'Ready to start',
                  description: 'See how Multiply can help grow your business.'
                }
              ].map((item) => (
                <div key={item.number} className="flex items-start gap-3">
                  <div 
                    className={`
                      flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center text-sm
                      transition-colors duration-300 z-10
                      ${item.number <= activeStep 
                        ? 'bg-green-500 text-white' 
                        : 'bg-gray-200 text-gray-600'}
                    `}
                  >
                    {item.number}
                  </div>
                  <div>
                    <h3 className="font-medium">{item.title}</h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdPreview;