import React, { useState, useEffect } from 'react';
import { Slider } from '@/components/ui/slider';
import { Card, CardHeader, CardContent } from '@/components/ui/card';
import { baseUrl } from '@/services/api-config';
import { StepStatus } from '@/utils/Interfaces';
import { useAuth } from '@/utils/AuthProvider'
import axios from 'axios';

interface PageProps {
  url: string;
  onStepComplete?: (status: StepStatus) => void;
}

interface BillingState {
    user_id: string;
    daily_budget: number;
}

const OverlaySetBudgetPage: React.FC<PageProps> = ({ url, onStepComplete }) => {
  const MINIMUM_BUDGET = 10;
  const [dailyBudget, setDailyBudget] = useState(MINIMUM_BUDGET);
  const [weeklyInvoice, setWeeklyInvoice] = useState(0);
  const [budgetError, setBudgetError] = useState<string | null>(null);
  const { session, refreshSession, userStatus } = useAuth();
  const [suggestedBudget, setSuggestedBudget] = useState<number | null>(null);
  const [billingState, setBillingState] = useState<BillingState | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSuggestedBudget = async () => {
    try {
      const suggested_budget = localStorage.getItem(`${url}_SUGGESTED_BUDGET`);

      if (!suggested_budget) {
        const resp = await axios.get(`${baseUrl}/googleads/campaign/budget/suggestion?url=${encodeURIComponent(url)}`);

        if (resp.data.suggested_budget) {
          const budget = Math.max(MINIMUM_BUDGET, Number(resp.data.suggested_budget)/1000000);
          setSuggestedBudget(budget);
          localStorage.setItem(`${url}_SUGGESTED_BUDGET`, budget.toString());
        }
      } else {
        setSuggestedBudget(Math.max(MINIMUM_BUDGET, Number(suggested_budget)));
      }
    } catch (error) {
      console.error('Failed to fetch suggested budget:', error);
      setBudgetError('Unable to load the suggested budget. You can still adjust your current budget.');
    }
  };

  useEffect(() => {
    const fetchBillingState = async () => {

        if (!session) {
            await refreshSession();
        }

        if (!session) {
            console.error("Unable to refresh session");
            return;
        }

        const headers = {
            Authorization: `Bearer ${session.access_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.get(
                `${baseUrl}/api/dailyspend`,
                { headers }
            );
            setBillingState(response.data);
            setDailyBudget(Math.max(MINIMUM_BUDGET, response.data.daily_budget));
        } catch (error) {
            console.error('Failed to fetch billing state:', error);
            setBudgetError('Unable to load current billing information. Please try again later.');
        }
    };

    const initialize = async () => {
      try {
        await Promise.all([
          fetchBillingState(),
          fetchSuggestedBudget()
        ]);
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [url]);

  useEffect(() => {
    if (!onStepComplete) return;

    const isBudgetValid = dailyBudget >= MINIMUM_BUDGET;
    const isBudgetChanged = billingState ? dailyBudget !== billingState.daily_budget : false;

    onStepComplete({
      isComplete: isBudgetValid && isBudgetChanged,
      message: !isBudgetValid 
        ? 'Please set a valid daily budget'
        : !isBudgetChanged
        ? 'Please adjust the budget to continue'
        : undefined
    });
  }, [dailyBudget, billingState, onStepComplete]);

  useEffect(() => {
    const multiplyFee = dailyBudget * 0.1;
    setWeeklyInvoice(multiplyFee * 7);
  }, [dailyBudget]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-6">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  const budgetDifference = billingState ? dailyBudget - billingState.daily_budget : 0;
  const weeklyFee = ((billingState?.daily_budget ?? 0) * 0.1) * 7
  const weeklyDifference = weeklyInvoice - (weeklyFee || 0);

  const maxBudget = Math.max(100, suggestedBudget || 100);

  return (
    <div className="p-6 max-w-3xl mx-auto">
      <Card>
        <CardHeader>
          <h2 className="text-2xl font-semibold">Adjust Campaign Budget</h2>
          {billingState && (
            <p className="text-gray-600">
              Current daily budget: ${billingState.daily_budget.toFixed(2)}
            </p>
          )}
        </CardHeader>
        <CardContent>
          {/* Suggested Budget Alert */}
          {suggestedBudget && suggestedBudget > dailyBudget && (
            <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-md">
              <p className="text-blue-700">
                Based on our AI analysis, increasing your budget to ${suggestedBudget.toFixed(2)}/day 
                could improve your campaign performance.
              </p>
            </div>
          )}

          {/* Error Alert */}
          {budgetError && (
            <div className="mb-6 p-4 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-700">{budgetError}</p>
            </div>
          )}

          {/* Budget Slider */}
          <div className="space-y-6">
            <Slider
              min={MINIMUM_BUDGET}
              max={maxBudget}
              step={1}
              value={[dailyBudget]}
              onValueChange={(value) => setDailyBudget(value[0])}
              className="mt-6"
            />

            <div className="flex justify-between text-sm text-gray-600">
              <span>${MINIMUM_BUDGET}</span>
              <span>${maxBudget}</span>
            </div>

            <div className="text-2xl font-bold">
              ${dailyBudget.toFixed(2)}/day
              <span className="text-sm text-gray-600 ml-2">USD</span>
            </div>

            {/* Budget Change Summary */}
            {budgetDifference !== 0 && billingState && (
              <div className="p-4 bg-gray-50 rounded-md space-y-3">
                <h3 className="font-semibold">Budget Change Summary</h3>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-gray-600">Daily budget change</span>
                    <span className={`font-medium ${budgetDifference > 0 ? 'text-green-600' : 'text-red-600'}`}>
                      {budgetDifference > 0 ? '+' : ''}{budgetDifference.toFixed(2)}/day
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-gray-600">Weekly fee change</span>
                    <span className={`font-medium ${weeklyDifference > 0 ? 'text-green-600' : 'text-red-600'}`}>
                      {weeklyDifference > 0 ? '+' : ''}${weeklyDifference.toFixed(2)}/week
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OverlaySetBudgetPage;