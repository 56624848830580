import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AdLocationSelector from '@/components/AdLocationSelector';
import { Button } from '@/components/ui/button';
import { Trash2, AlertCircle } from 'lucide-react';
import { useCampaign } from '@/contexts/CampaignContext'
import { LocationData, LocationSelection } from './page_types/location_data';



const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const GoogleAdsLocationPage: React.FC = () => {
  const {
    locationData,
    handleAddLocation,
    handleRemoveLocation,
    fetchLocationDataIfNeeded,
    locationDataLoading
  } = useCampaign();

  const [selectedLocation, setSelectedLocation] = useState<LocationSelection | null>(null);
  const [targetingType, setTargetingType] = useState<'PROXIMITY' | 'LOCATION' | null>(null);

  useEffect(() => {
    fetchLocationDataIfNeeded();
  }, [fetchLocationDataIfNeeded]);

  useEffect(() => {
    if (locationData.length > 0) {
      setTargetingType(locationData[0].type);
    } else {
      setTargetingType(null);
    }
  }, [locationData]);

  const prepareChartData = (data: LocationData[]) => {
    return data.map(location => ({
      name: location.type === 'LOCATION'
        ? location.resource_name
        : `${location.coordinates.lat.toFixed(2)}, ${location.coordinates.lng.toFixed(2)}`,
      clicks: location.clicks,
      conversions: location.conversions,
      cost: location.average_cost
    }));
  };

  const handleLocationSelect = (location: LocationSelection) => {
    setSelectedLocation(location);
    if (locationData.length === 0) {
      setTargetingType(location.type);
    }
  };

  const onAddLocation = async () => {
    if (!selectedLocation) {
      return;
    }

    const success = await handleAddLocation(selectedLocation);
    if (success) {
      setSelectedLocation(null);
    }
  };

  return (
    <div className="flex-1 p-8">
      <h1 className="text-2xl font-bold mb-4">Ad Locations Overview</h1>

      <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg flex items-start">
        <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
        <div>
          <h4 className="font-semibold text-blue-800">Important: Targeting Type Restriction</h4>
          <p className="text-blue-700">
            You can only use one type of targeting (Location or Proximity) per campaign.
            {targetingType && ` Current campaign is using ${targetingType} targeting.`}
          </p>
        </div>
      </div>

      <div className="mb-6">
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Current Targeting Type</h3>
          <p className="text-3xl font-bold">{targetingType || 'None'}</p>
        </div>
      </div>

      {locationData.length > 0 && (
        <div className="bg-white p-6 rounded shadow mb-6">
          <h3 className="text-lg font-semibold mb-4">Location Performance</h3>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={prepareChartData(locationData)}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="clicks" fill="#8884d8" name="Clicks" />
              <Bar yAxisId="left" dataKey="conversions" fill="#82ca9d" name="Conversions" />
              <Bar yAxisId="right" dataKey="cost" fill="#ffc658" name="Avg. Cost ($)" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      <div className="bg-white p-6 rounded shadow mb-6">
        {locationData.length === 0 ? (
          <div className="p-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700">
            <p>No location targeting data available. Set up your first location target below.</p>
          </div>
        ) : (
          <div className="space-y-2">
            {locationData.map((location) => (
              <div key={location.resource_name}
                className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                <div className="flex-1">
                  {location.type === 'LOCATION' ? (
                    // Location targeting display
                    <>
                      <div className="font-medium">
                        {location.resource_name}
                      </div>
                      <div className="text-sm text-gray-600">
                        Location targeting
                      </div>
                    </>
                  ) : (
                    // Proximity targeting display
                    <>
                      <div className="font-medium">
                        Radius: {location.radius_miles} miles
                      </div>
                      <div className="text-sm text-gray-600">
                        Coordinates: {location.coordinates.lat.toFixed(4)}, {location.coordinates.lng.toFixed(4)}
                      </div>
                    </>
                  )}
                  <div className="text-sm text-gray-600 mt-1">
                    Performance: {location.clicks} clicks, {location.conversions.toFixed(2)} conversions
                  </div>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleRemoveLocation(location.resource_name)}
                  className="text-red-500 hover:text-red-700 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-white p-6 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">
          Add New Location {targetingType && `(${targetingType} Targeting)`}
        </h3>
        <AdLocationSelector
          onLocationSelect={handleLocationSelect}
          disabled={false}
        />

        {selectedLocation && (
          <div className="mt-4 flex justify-end">
            <Button
              onClick={onAddLocation}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Add Selected Location
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6 p-4 bg-blue-100 border border-blue-200 rounded">
        <h4 className="font-semibold text-blue-800">Location Targeting Tips</h4>
        <p className="text-blue-700">
          Choose between Location or Proximity targeting based on your needs:
        </p>
        <ul className="mt-2 list-disc list-inside text-blue-700">
          <li>Location targeting: Best for targeting specific cities, regions, or countries</li>
          <li>Proximity targeting: Best for targeting specific radius around exact coordinates</li>
        </ul>
      </div>
    </div>
  );
};

export default GoogleAdsLocationPage;