const PrivacyPolicy = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-4"><strong>Last updated:</strong> 10/21/2024</p>
      
      <p className="mb-4">
        Welcome to Multiply ("Company," "we," "our," or "us"). This Privacy Policy explains how we collect, use, 
        disclose, and safeguard your information when you visit our website https://trymultiply.com/ (the "Site"). 
        Please read this privacy policy carefully. If you do not agree with the terms of this policy, please do not 
        access the Site.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        We collect personal information you provide directly when creating an account, making a purchase, or 
        otherwise interacting with the Site. This includes:
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Personal Data: Names, email addresses, usernames, billing addresses, and debit/credit card numbers.</li>
        <li className="mb-2">Derivative Data: Information our servers automatically collect when you access the Site, such as log and usage data, device data, and location data.</li>
      </ul>
      <p className="mb-4">
        You may also register on the Site using your Google account. By doing so, we collect and use information 
        consistent with Google's terms of service.
      </p>
      <p className="mb-4">We do not knowingly collect data from or target individuals under the age of 18.</p>

      <h2 className="text-xl font-bold mt-6 mb-2">2. How We Use Your Information</h2>
      <p className="mb-4">We use your information to:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Deliver and facilitate the delivery of services to you.</li>
        <li>Fulfill contractual obligations, including processing payments via Stripe.</li>
        <li>Respond to your inquiries and send you administrative information.</li>
        <li>Deliver targeted advertising and promotional communications.</li>
        <li>Analyze trends and user behavior to enhance our services.</li>
        <li>Ensure the security of the Site.</li>
        <li>Request feedback and improve the user experience.</li>
      </ul>

      {/* Continue with sections 3-12... */}
      <h2 className="text-xl font-bold mt-6 mb-2">12. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this privacy policy or how your data is handled, please 
        contact us at the email provided on the Site.
      </p>
    </div>
  );
};

export default PrivacyPolicy;