import React, { useState, useEffect } from 'react';
import { AlertCircle, Save, Loader2 } from 'lucide-react';
import { toast } from 'react-toastify';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { useCampaign } from '@/contexts/CampaignContext';

type CreativeType = 'HEADLINE' | 'DESCRIPTION';

interface AdItem {
  text: string;
  status: 'SYNCED' | 'PENDING_ADD' | 'PENDING_REMOVE';
  type: CreativeType;
}

interface ApiResponse {
  headlines: string[];
  descriptions: string[];
}

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, x: -20 }
};

interface EditorProps {
  items: AdItem[];
  onUpdate: (items: AdItem[]) => void;
  isPending: boolean;
  type: CreativeType;
  maxItems: number;
}

const CreativeEditor: React.FC<EditorProps> = ({ 
  items, 
  onUpdate, 
  isPending, 
  type, 
  maxItems 
}) => {
  const [newItem, setNewItem] = useState('');
  const isHeadline = type === 'HEADLINE';

  const addItem = () => {
    if (newItem && items.length < maxItems) {
      onUpdate([...items, { 
        text: newItem, 
        status: 'PENDING_ADD',
        type 
      }]);
      setNewItem('');
      toast.success(`${isHeadline ? 'Headline' : 'Description'} added - remember to save your changes!`);
    } else if (items.length >= maxItems) {
      toast.warning(`Maximum number of ${isHeadline ? 'headlines' : 'descriptions'} reached (${maxItems})`);
    }
  };

  const removeItem = (index: number) => {
    const updatedItems = [...items];
    if (updatedItems[index].status === 'PENDING_ADD') {
      updatedItems.splice(index, 1);
      toast.info(`Pending ${isHeadline ? 'headline' : 'description'} removed`);
    } else {
      updatedItems[index].status = 'PENDING_REMOVE';
      toast.info(`${isHeadline ? 'Headline' : 'Description'} marked for removal - remember to save your changes!`);
    }
    onUpdate(updatedItems);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-white p-6 rounded-lg shadow ${!isHeadline ? 'mt-6' : ''}`}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-xl font-semibold">{isHeadline ? 'Headlines' : 'Descriptions'}</h2>
        <AnimatePresence>
          {isPending && (
            <motion.span
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              className="text-yellow-600 text-sm flex items-center gap-1"
            >
              <AlertCircle size={16} />
              Unsaved changes
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      <p className="text-gray-600 mb-4">
        {isHeadline 
          ? 'The titles of your ads, mixed and matched for better performance. Add up to 15.'
          : 'The text in your ads, paired with various headlines. Add up to 4.'
        }
      </p>
      
      <AnimatePresence>
        {items.map((item, index) => (
          item.status !== 'PENDING_REMOVE' && (
            <motion.div
              key={`${item.type}-${index}-${item.text}`}
              variants={itemVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              layout
              className="flex gap-2 mb-2 items-center"
            >
              {item.status === 'PENDING_ADD' ? (
                <div className="flex-grow p-2 border rounded bg-blue-50 flex justify-between items-center">
                  <span>{item.text}</span>
                  <span className="text-sm text-blue-600">Pending Save</span>
                </div>
              ) : (
                <div className="flex-grow p-2 border rounded">
                  {item.text}
                </div>
              )}
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => removeItem(index)}
                className="px-3 py-2 text-red-600 hover:bg-red-50 rounded"
              >
                Remove
              </motion.button>
            </motion.div>
          )
        ))}
      </AnimatePresence>
      
      <div className="flex mt-4">
        {isHeadline ? (
          <input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Enter a new headline"
            className="flex-grow p-2 border rounded-l"
            onKeyPress={(e) => e.key === 'Enter' && addItem()}
          />
        ) : (
          <textarea
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Enter a new description"
            className="flex-grow p-2 border rounded-l"
            rows={2}
          />
        )}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={addItem}
          disabled={items.filter(i => i.status !== 'PENDING_REMOVE').length >= maxItems}
          className="bg-blue-500 text-white px-4 py-2 rounded-r disabled:bg-blue-300"
        >
          Add {isHeadline ? 'headline' : 'description'}
        </motion.button>
      </div>
    </motion.div>
  );
};

const ManageAdContent: React.FC = () => {
  const [creatives, setCreatives] = useState<AdItem[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const {
    campaignId,
    previewLoading
  } = useCampaign();

  const headlines = creatives.filter(item => item.type === 'HEADLINE');
  const descriptions = creatives.filter(item => item.type === 'DESCRIPTION');

  const { session, refreshSession } = useAuth();

  const hasPendingChanges = creatives.some(
    item => item.status === 'PENDING_ADD' || item.status === 'PENDING_REMOVE'
  );

  const handleUpdate = (newItems: AdItem[]) => {
    const existingItems = creatives.filter(item => item.type !== newItems[0].type);
    setCreatives([...existingItems, ...newItems]);
  };

  useEffect(() => {
    const loadAdContent = async () => {
      try {
        if (!session?.access_token) {
          await refreshSession();
        }
    
        const headers = {
          Authorization: `Bearer ${session?.access_token}`,
          'Content-Type': 'application/json',
        };
    
        const response = await axios.get<ApiResponse>(
          `${baseUrl}/googleads/campaign/${campaignId}/creative`,
          { headers }
        );
    
        const { headlines, descriptions } = response.data;
    
        const loadedCreativeItems: AdItem[] = [];
    
        for (const headline of headlines) {
          loadedCreativeItems.push({
            text: headline,
            status: 'SYNCED' as const,
            type: 'HEADLINE' as CreativeType
          });
        }
    
        for (const description of descriptions) {
          loadedCreativeItems.push({
            text: description,
            status: 'SYNCED' as const,
            type: 'DESCRIPTION' as CreativeType
          });
        }
    
        setCreatives(loadedCreativeItems);
      } catch (err) {
        toast.error('Failed to load ad content');
        console.error('Error loading ad content:', err);
      }
    };
    
    loadAdContent();
  }, [campaignId, session?.access_token, refreshSession]);

  const handleSave = async () => {
    const toastId = toast.loading("Saving changes...");
    setIsSaving(true);
    
    try {
      if (!session?.access_token) {
        await refreshSession();
      }

      const operations = creatives
        .filter(item => item.status !== 'SYNCED')
        .map(item => ({
          operation: item.status === 'PENDING_ADD' ? 'add' : 'remove',
          text: item.text,
          field_type: item.type
        }));

      if (operations.length > 0) {
        const headers = {
          Authorization: `Bearer ${session?.access_token}`,
          'Content-Type': 'application/json',
        };

        const response = await axios.post(
          `${baseUrl}/googleads/campaign/creative/update`,
          {
            campaign_id: campaignId,
            ad_creative_operations: operations
          },
          { headers }
        );

        if (!response.data) {
          throw new Error('Failed to save changes');
        }

        setCreatives(prev => 
          prev
            .filter(item => item.status !== 'PENDING_REMOVE')
            .map(item => ({ ...item, status: 'SYNCED' as const }))
        );

        toast.update(toastId, {
          render: "Changes saved successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.dismiss(toastId);
      }
    } catch (error) {
      console.error('Save error:', error);
      toast.update(toastId, {
        render: "Failed to save changes. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="space-y-6"
    >
      <CreativeEditor 
        items={headlines}
        onUpdate={(items) => handleUpdate(items)}
        isPending={headlines.some(h => h.status !== 'SYNCED')}
        type="HEADLINE"
        maxItems={15}
      />
      
      <CreativeEditor 
        items={descriptions}
        onUpdate={(items) => handleUpdate(items)}
        isPending={descriptions.some(d => d.status !== 'SYNCED')}
        type="DESCRIPTION"
        maxItems={4}
      />
      
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex justify-end mt-6"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleSave}
          disabled={!hasPendingChanges || isSaving}
          className="flex items-center gap-2 bg-green-600 text-white px-6 py-3 rounded-lg disabled:bg-gray-400"
        >
          {isSaving ? (
            <Loader2 className="animate-spin" size={20} />
          ) : (
            <Save size={20} />
          )}
          Save Changes
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default ManageAdContent;