import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Slider } from '@/components/ui/slider';
import { StepStatus } from '@/utils/Interfaces';

interface ConversionProfileProps {
  onStepComplete?: (status: StepStatus) => void;
}

interface ConversionData {
  conversionType: 'leads' | 'purchases';
  monthly: string;
  average: string;
  profit: string;
  conversionRate?: number[];
}

const OverlayConversionProfile: React.FC<ConversionProfileProps> = ({ onStepComplete }) => {
  const [conversionType, setConversionType] = useState<'leads' | 'purchases'>('leads');
  const [monthly, setMonthly] = useState('');
  const [average, setAverage] = useState('');
  const [profit, setProfit] = useState('');
  const [conversionRate, setConversionRate] = useState<number[]>([25]);
  const [isFormValid, setIsFormValid] = useState(false);

  // Load saved data from localStorage on mount
  useEffect(() => {
    const savedData = localStorage.getItem('conversion_profile');
    if (savedData) {
      const data: ConversionData = JSON.parse(savedData);
      setConversionType(data.conversionType);
      setMonthly(data.monthly);
      setAverage(data.average);
      setProfit(data.profit);
      if (data.conversionRate) {
        setConversionRate(data.conversionRate);
      }
    }
  }, []);

  // Validate form and save to localStorage
  useEffect(() => {
    const isValid = 
      monthly !== '' && 
      average !== '' && 
      profit !== '' && 
      (conversionType === 'purchases' || (conversionType === 'leads' && conversionRate.length > 0));

    setIsFormValid(isValid);

    if (isValid) {
      const conversionData: ConversionData = {
        conversionType,
        monthly,
        average,
        profit,
        ...(conversionType === 'leads' && { conversionRate })
      };
      localStorage.setItem('conversion_profile', JSON.stringify(conversionData));
    }

    if (onStepComplete) {
      onStepComplete({
        isComplete: isValid,
        message: !isValid ? 'Please fill out all required fields' : undefined
      });
    }
  }, [monthly, average, profit, conversionType, conversionRate, onStepComplete]);

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold mb-2">Set up your conversion profile</h2>
        <p className="text-gray-600 mb-6">
          Multiply will use this information to optimize your conversion tracking, ad spend,
          performance, and more.
        </p>

        <div className="space-y-6">
          <RadioGroup
            value={conversionType}
            onValueChange={(value: 'leads' | 'purchases') => setConversionType(value)}
            className="grid grid-cols-2 gap-4"
          >
            <div className={`border rounded-lg p-4 ${conversionType === 'leads' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="leads" id="leads" className="sr-only" />
              <Label htmlFor="leads" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Leads</span>
                <span className="text-gray-600">
                  For businesses looking to generate leads. This includes service-based
                  businesses, such as electricians.
                </span>
              </Label>
            </div>
            <div className={`border rounded-lg p-4 ${conversionType === 'purchases' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="purchases" id="purchases" className="sr-only" />
              <Label htmlFor="purchases" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Purchases</span>
                <span className="text-gray-600">
                  For businesses looking to drive sales. This includes product-based
                  businesses, such as online stores.
                </span>
              </Label>
            </div>
          </RadioGroup>

          <div className="space-y-4">
            <div>
              <Label htmlFor="monthly">
                {conversionType === 'leads' ? 'Monthly leads' : 'Monthly sales'}
              </Label>
              <Input
                id="monthly"
                type="number"
                placeholder="Enter a number"
                className="mt-1"
                value={monthly}
                onChange={(e) => setMonthly(e.target.value)}
                required
              />
            </div>
            <div>
              <Label htmlFor="average">
                {conversionType === 'leads' ? 'Average customer spend' : 'Average order value'}
              </Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="average"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={average}
                  onChange={(e) => setAverage(e.target.value)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            <div>
              <Label htmlFor="profit">Estimated profit</Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="profit"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={profit}
                  onChange={(e) => setProfit(e.target.value)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            {conversionType === 'leads' && (
              <div>
                <Label htmlFor="conversion-rate">Conversion rate</Label>
                <Slider
                  id="conversion-rate"
                  min={0}
                  max={100}
                  step={1}
                  value={conversionRate}
                  onValueChange={setConversionRate}
                  className="mt-2"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>0%</span>
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OverlayConversionProfile;