import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import GoogleAdPreview from '../AdPreviewPageV2';
import LocationTargetingPage from '../LocationTargetingPage';
import OnboardingStart from './OverlayStart';
import { StepStatus } from '@/utils/Interfaces';
import { DOMAIN_KEY } from '@/types/constants';
import OverlaySetBudgetPage from './OverlaySetBudget';
import OverlayConversionProfile from './OverlayConversionProfile';
import CampaignSummaryOverlay from './CreateCampaignStep';

interface OnboardingOverlayProps {
  isOpen: boolean;
  onClose: () => void;
  domain?: string;
}

type StepComponent = React.ComponentType<any>;

const OnboardingOverlay: React.FC<OnboardingOverlayProps> = ({ isOpen, onClose, domain }) => {
  const navigate = useNavigate();
  
  const steps: StepComponent[] = [
    OnboardingStart,
    GoogleAdPreview,
    LocationTargetingPage,
    OverlaySetBudgetPage,
    OverlayConversionProfile,
    CampaignSummaryOverlay
  ];
  
  const [stepStatus, setStepStatus] = useState<StepStatus>({ isComplete: false });
  const [localDomain, setLocalDomain] = useState<string>(domain || '');
  const [step, setStep] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    onClose();
    navigate('/manage-campaign');
  };

  useEffect(() => {
    if (!isOpen) return;

    if (!localDomain) {
      const storedDomain = localStorage.getItem(DOMAIN_KEY);
      if (!storedDomain) {
        handleClose();
        return;
      }
      setLocalDomain(storedDomain);
    }

    const stepParam = searchParams.get('onboardingStep');
    if (stepParam !== null) {
      const newStep = parseInt(stepParam, 10);
      if (!isNaN(newStep) && newStep >= 0 && newStep < steps.length) {
        setStep(newStep);
        if (newStep === 1 && !searchParams.get('step')) {
          setSearchParams({ 
            onboardingStep: newStep.toString(),
            step: '0'
          });
        }
      }
    } else {
      setStep(0);
      setSearchParams({ onboardingStep: '0' }, { replace: true });
    }
  }, [isOpen, localDomain, searchParams, setSearchParams, steps.length]);

  if (!isOpen) return null;

  const handleStepComplete = (status: StepStatus) => {
    setStepStatus(status);
  };

  const handleStartComplete = (url: string) => {
    setLocalDomain(url);
    setStepStatus({ isComplete: true });
    const newStep = 1;
    setStep(newStep);
    setSearchParams({ 
      onboardingStep: newStep.toString(),
      step: '0'
    });
  };

  const isLastStep = () => step === steps.length - 1;
  
  const isNextButtonDisabled = () => {
    return !stepStatus.isComplete;
  };

  const nextStep = () => {
    if (isNextButtonDisabled()) return;
    
    if (isLastStep()) {
      handleClose();
      setSearchParams({});
      return;
    }

    const newStep = step + 1;
    setStep(newStep);
    setStepStatus({ isComplete: false });
    setSearchParams({ onboardingStep: newStep.toString() });
  };

  const prevStep = () => {
    const newStep = Math.max(step - 1, 0);
    setStep(newStep);
    setSearchParams({ onboardingStep: newStep.toString() });
  };

  const Page = steps[step];

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-black/50" onClick={handleClose} />
      <div className="relative min-h-screen flex items-center justify-center p-4">
        <div className="relative bg-white rounded-lg shadow-xl max-w-4xl w-full p-6">
          <button
            onClick={handleClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Close</span>
            ×
          </button>
          
          {step === 0 ? (
            <Page onComplete={handleStartComplete} />
          ) : (
            <Page url={localDomain} onStepComplete={handleStepComplete} />
          )}
          
          <div className="flex justify-between mt-8">
            {step > 0 ? (
              <button
                onClick={prevStep}
                className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400"
              >
                Previous
              </button>
            ) : (
              <div />
            )}
            
            <div className="flex items-center gap-4">
              {!stepStatus.isComplete && stepStatus.message && (
                <span className="text-red-500 text-sm">{stepStatus.message}</span>
              )}
              <button
                onClick={nextStep}
                disabled={isNextButtonDisabled()}
                className={`px-4 py-2 rounded text-white ${
                  isNextButtonDisabled()
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-primary hover:bg-primaryDark'
                }`}
              >
                {isLastStep() ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingOverlay;