export type NavigationItem = 'overview' | 'keywords' | 'budget' | 'adContent' | 'locationTargeting' | 'settings';

export const navigationItems = [
  { id: 'overview' as NavigationItem, label: 'Overview', icon: '📊' },
  { id: 'budget' as NavigationItem, label: 'Budget', icon: '💰' },
  { id: 'adContent' as NavigationItem, label: 'Ad content', icon: '✏️' },
  { id: 'keywords' as NavigationItem, label: 'Keywords', icon: '🔑' },
  { id: 'locationTargeting' as NavigationItem, label: 'Location targeting', icon: '📍' },
  { id: 'settings' as NavigationItem, label: 'Settings', icon: '⚙️' },
] as const;

export interface NavigationItemType {
  id: NavigationItem;
  label: string;
  icon: string;
}