import React, { useState, useEffect } from 'react';
import { useAuth } from '@/utils/AuthProvider';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Pencil, Loader2, Save, TrendingUp, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCampaign } from '@/contexts/CampaignContext';


const GoogleAdsBudgetPage: React.FC = () => {
  const { session, refreshSession } = useAuth();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditingBudget, setIsEditingBudget] = useState(false);
  const [newDailyBudget, setNewDailyBudget] = useState<string>('');

  const {
    budgetLoading,
    campaignId,
    suggestedBudget,
    budgetData,
    budgetHistory,
    setBudgetData,
  } = useCampaign();

  const getNextSunday = () => {
    const today = new Date();
    const daysUntilSunday = 7 - today.getDay();
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + daysUntilSunday);
    return nextSunday.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleUpdateBudget = async () => {
    if (!newDailyBudget || isNaN(Number(newDailyBudget))) {
      toast.error('Please enter a valid budget amount');
      return;
    }

    const confirmMessage = `This budget change will take effect in the next billing cycle. Your card will be charged ${((Number(newDailyBudget) * 0.1) * 7).toFixed(2)} USD (10% of the new budget) on ${getNextSunday()}. Do you want to proceed?`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    setIsUpdating(true);
    const toastId = toast.loading("Updating budget...");

    try {
      if (!session?.access_token) {
        await refreshSession();
      }

      const headers = {
        Authorization: `Bearer ${session?.access_token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        `${baseUrl}/googleads/campaign/${campaignId}/budget`,
        { dailyBudget: Number(newDailyBudget) },
        { headers }
      );

      if (response.status === 200) {

        setBudgetData({
          daily_budget: Number(newDailyBudget),
          monthly_budget: Number(newDailyBudget) * 30,
          spent_today: budgetData?.remaining_budget ?? 0,
          spent_this_month: budgetData?.spent_this_month ?? 0,
          remaining_budget: Number(newDailyBudget) - (budgetData?.spent_this_month ?? 0)
        });

        setIsEditingBudget(false);
        setNewDailyBudget('');
        toast.update(toastId, {
          render: "Budget updated successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error('Error updating budget:', error);
      toast.update(toastId, {
        render: "Failed to update budget. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  if (isUpdating) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="animate-spin" size={32} />
      </div>
    );
  }


  return (
    <div className="flex-1 p-8">
      {suggestedBudget > (budgetData?.daily_budget ?? 0) && (
        <div className="flex items-center gap-3 p-4 mb-6 rounded-lg border border-blue-200 bg-blue-50">
          <TrendingUp className="h-5 w-5 text-blue-600 flex-shrink-0" />
          <div className="flex-1 text-blue-800">
            Our AI suggests increasing your daily budget to ${suggestedBudget.toFixed(2)} to optimize your campaign performance.
            {isEditingBudget ? (
              <button 
                onClick={() => setNewDailyBudget(suggestedBudget.toString())}
                className="ml-2 text-blue-600 hover:text-blue-700 underline font-medium"
              >
                Apply suggestion
              </button>
            ) : (
              <button 
                onClick={() => {
                  setIsEditingBudget(true);
                  setNewDailyBudget(suggestedBudget.toString());
                }}
                className="ml-2 text-blue-600 hover:text-blue-700 underline font-medium"
              >
                Update budget
              </button>
            )}
          </div>
        </div>
      )}

      <h1 className="text-2xl font-bold mb-4">Budget Overview</h1>

      <div className="grid grid-cols-2 gap-6 mb-6">
        <div className="bg-white p-6 rounded shadow">
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">Daily Budget</h3>
            {!isEditingBudget && (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsEditingBudget(true)}
                className="text-blue-500 hover:text-blue-600"
              >
                <Pencil size={20} />
              </motion.button>
            )}
          </div>
          <AnimatePresence mode="wait">
            {isEditingBudget ? (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                className="space-y-2"
              >
                <input
                  type="number"
                  value={newDailyBudget}
                  onChange={(e) => setNewDailyBudget(e.target.value)}
                  placeholder="Enter new daily budget"
                  className="w-full border rounded p-2"
                />
                {newDailyBudget && (
                  <div className="flex items-start gap-2 p-3 mb-4 rounded bg-yellow-50 border border-yellow-200 text-yellow-800">
                    <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                    <span>
                      Changes will take effect next billing cycle. A charge of ${(Number(newDailyBudget) * 0.1).toFixed(2)} (10% of new budget) will be processed on {getNextSunday()}.
                    </span>
                  </div>
                )}
                <div className="flex gap-2">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={handleUpdateBudget}
                    disabled={isUpdating}
                    className="flex items-center gap-2 bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 disabled:bg-gray-400"
                  >
                    {isUpdating ? (
                      <Loader2 className="animate-spin" size={16} />
                    ) : (
                      <Save size={16} />
                    )}
                    Save
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      setIsEditingBudget(false);
                      setNewDailyBudget('');
                    }}
                    disabled={isUpdating}
                    className="px-4 py-2 border rounded hover:bg-gray-50"
                  >
                    Cancel
                  </motion.button>
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
              >
                <p className="text-3xl font-bold">${budgetData?.daily_budget.toFixed(2)}</p>
                <p className="text-gray-500">Set your daily spending limit</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Monthly Budget</h3>
          <p className="text-3xl font-bold">${budgetData?.monthly_budget.toFixed(2)}</p>
          <p className="text-gray-500">Estimated monthly spend</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 mb-6">
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Spent Today</h3>
          <p className="text-3xl font-bold">${budgetData?.spent_today.toFixed(2)}</p>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Spent This Month</h3>
          <p className="text-3xl font-bold">${budgetData?.spent_this_month.toFixed(2)}</p>
        </div>
        <div className="bg-white p-6 rounded shadow">
          <h3 className="text-lg font-semibold mb-2">Remaining Budget</h3>
          <p className="text-3xl font-bold">${budgetData?.remaining_budget.toFixed(2)}</p>
        </div>
      </div>

      <h2 className="text-xl font-semibold mb-4">Budget History</h2>

      <div className="bg-white p-6 rounded shadow">
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={budgetHistory}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="spent" stroke="#8884d8" />
            <Line type="monotone" dataKey="budget" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GoogleAdsBudgetPage;