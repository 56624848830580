import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// Initialize Stripe (replace with your publishable key)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DETAILS!);

interface PaymentMethodResponse {
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  is_default: boolean;
  payment_method_id: string;
}

// Card element options
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const StripeContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
      },
    ],
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

const PaymentMethodDisplay: React.FC<{
  paymentMethod: PaymentMethodResponse;
  onUpdateClick: () => void;
}> = ({ paymentMethod, onUpdateClick }) => {
  return (
    <div className="p-4 border rounded-md bg-gray-50">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <p className="font-medium capitalize">
            {paymentMethod.brand} •••• {paymentMethod.last4}
          </p>
          <p className="text-sm text-gray-500">
            Expires {paymentMethod.exp_month.toString().padStart(2, '0')}/{paymentMethod.exp_year}
          </p>
        </div>
        <button
          onClick={onUpdateClick}
          className="text-blue-600 hover:text-blue-700 text-sm"
        >
          Update
        </button>
      </div>
    </div>
  );
};

const CheckoutForm: React.FC<{ 
  onClose: () => void;
  existingPaymentMethod?: PaymentMethodResponse;
}> = ({ onClose, existingPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [weeklySpend, setWeeklySpend] = useState<number>(100);
  const [showCardInput, setShowCardInput] = useState(!existingPaymentMethod);
  const { session, refreshSession } = useAuth();

  const weeklyCharge = (weeklySpend * 0.10).toFixed(2);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements || weeklySpend <= 0) {
      return;
    }

    setIsLoading(true);

    try {
      let paymentMethodId = existingPaymentMethod?.payment_method_id;

      // If showing card input, create new payment method
      if (showCardInput) {
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
          throw new Error('Card element not found');
        }

        const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (createError) {
          setMessage(createError.message || "Failed to process card");
          toast.error(createError.message);
          return;
        }

        if (!paymentMethod) {
          setMessage("Failed to create payment method");
          return;
        }

        paymentMethodId = paymentMethod.id;
      }

      if (!paymentMethodId) {
        setMessage("No payment method available");
        return;
      }

      // Create subscription with either new or existing payment method
      if (!session?.access_token) {
        await refreshSession();
      }

      // If using a new payment method, set it up first
      if (showCardInput) {
        const setupResponse = await axios.post(
          `${baseUrl}/api/subscriptions/setup`,
          {
            payment_method_id: paymentMethodId,
            weekly_spend: weeklySpend,
          },
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        const { client_secret } = setupResponse.data;

        const { error: confirmError } = await stripe.confirmCardSetup(
          client_secret,
          {
            payment_method: paymentMethodId,
          }
        );

        if (confirmError) {
          setMessage(confirmError.message || "Failed to confirm setup");
          toast.error(confirmError.message);
          return;
        }
      }

      // Create/update subscription
      await axios.post(
        `${baseUrl}/api/subscriptions/create`,
        {
          payment_method_id: paymentMethodId,
          weekly_spend: weeklySpend,
        },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Payment method and weekly spend updated successfully');
      onClose();
    } catch (err) {
      setMessage("An unexpected error occurred.");
      toast.error("An unexpected error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="weeklySpend" className="block text-sm font-medium text-gray-700">
          Weekly Platform Spend
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="number"
            name="weeklySpend"
            id="weeklySpend"
            min="1"
            step="1"
            value={weeklySpend}
            onChange={(e) => setWeeklySpend(Number(e.target.value))}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder="0.00"
          />
        </div>
        <p className="mt-2 text-sm text-blue-600">
          Weekly charge (10%): ${weeklyCharge}
        </p>
      </div>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Payment Method
        </label>
        
        {existingPaymentMethod && !showCardInput ? (
          <div className="space-y-3">
            <PaymentMethodDisplay 
              paymentMethod={existingPaymentMethod}
              onUpdateClick={() => setShowCardInput(true)}
            />
          </div>
        ) : (
          <div className="p-3 border rounded-md">
            <CardElement
              id="card-element"
              options={CARD_ELEMENT_OPTIONS}
            />
          </div>
        )}
      </div>

      {message && (
        <div className="text-sm text-red-600 mb-4">
          {message}
        </div>
      )}

      <div className="flex justify-end space-x-4">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 rounded bg-gray-300 text-black hover:bg-gray-400"
          disabled={isLoading}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isLoading || !stripe || !elements || weeklySpend <= 0}
          className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 disabled:opacity-50"
        >
          {isLoading ? (
            <span className="flex items-center space-x-2">
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span>Processing...</span>
            </span>
          ) : (
            'Set Up Weekly Payments'
          )}
        </button>
      </div>
    </form>
  );
};

const SettingsPage: React.FC = () => {
  const [email, setEmail] = useState('N/A');
  const [cardNumber, setCardNumber] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { session, refreshSession, signOut } = useAuth();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodResponse | null>(null);
  const [setupIntent, setSetupIntent] = useState<string | null>(null);

  // Fetch payment method details on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!session?.access_token) {
          await refreshSession();
        }

        const response = await axios.get<PaymentMethodResponse>(
          `${baseUrl}/api/payment-methods`,
          {
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        setPaymentMethod(response.data);
        setCardNumber(`**** **** **** ${response.data.last4}`);
      } catch (error) {
        console.error('Error fetching payment method:', error);
        setCardNumber('No card on file');
      }
    };

    fetchData();
  }, [session, refreshSession]);

  const handleUpdatePayment = async () => {
    if (!session?.access_token) {
      await refreshSession();
    }

    try {
      const response = await axios.post(
        `${baseUrl}/api/subscriptions/setup-intent`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSetupIntent(response.data.client_secret);
      setIsPaymentModalOpen(true);
    } catch (error) {
      toast.error('Failed to initialize payment update');
    }
  };

  const updateEmail = async () => {
    if (!session?.access_token) {
      await refreshSession();
    }

    try {
      await axios.post(
        `${baseUrl}/account/update-email`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      toast.success('Email updated successfully');
    } catch (error) {
      toast.error('Failed to update email');
    }
  };

  const deleteAccount = async () => {
    if (!session?.access_token) {
      await refreshSession();
    }

    try {
      const response = await axios.post(
        `${baseUrl}/account/delete-account`,
        {},
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === 'success') {
        signOut();
      }
    } catch (error) {
      toast.error('Failed to delete account');
    }
  };

  return (
    <StripeContainer>
      <div className="space-y-6 p-6 max-w-3xl mx-auto">
        (
          <>
            <h2 className="text-2xl font-bold">Account Settings</h2>

            <div className="bg-white shadow rounded-lg">
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-4">Email Address</h3>
                <div className="flex items-center space-x-4">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex-grow border rounded px-3 py-2"
                  />
                  <button
                    onClick={updateEmail}
                    className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
                  >
                    Update Email
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg">
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-4">Payment Information</h3>
                <p className="text-gray-600 mb-4">
                  Current card on file: {cardNumber || 'No card on file'}
                </p>
                <button
                  onClick={handleUpdatePayment}
                  className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600"
                >
                  Update Payment Method
                </button>
              </div>
            </div>

            <div className="bg-white shadow rounded-lg">
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-4 text-red-600">Danger Zone</h3>
                <p className="text-gray-600 mb-4">
                  Permanently delete your account and all associated data.
                </p>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600"
                >
                  Delete Account
                </button>
              </div>
            </div>

            {/* Delete Account Modal */}
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-md w-full">
                  <h3 className="text-xl font-bold mb-4">
                    Are you sure you want to delete your account?
                  </h3>
                  <p className="text-gray-600 mb-6">
                    This action cannot be undone. This will permanently delete your account
                    and remove your data from our servers.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 rounded bg-gray-300 text-black hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={deleteAccount}
                      className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600"
                    >
                      Yes, Delete My Account
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Payment Modal */}
            {isPaymentModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg max-w-md w-full">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold">Set Up Weekly Payments</h3>
                    <button
                      onClick={() => setIsPaymentModalOpen(false)}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">Close</span>
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                  <Elements stripe={stripePromise}>
                    <CheckoutForm
                      onClose={() => setIsPaymentModalOpen(false)}
                      existingPaymentMethod={paymentMethod ?? undefined}
                    />
                  </Elements>
                </div>
              </div>
            )}
          </>
        )
      </div>
    </StripeContainer>
  );
};

export default SettingsPage;