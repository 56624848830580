import React from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { NavigationItem } from '@/types/navigation';


interface PageSkeletonsProps {
  selectedNav: NavigationItem;
}

const PageSkeletons: React.FC<PageSkeletonsProps> = ({ selectedNav }) => {
    const GoogleAdsAccountSkeleton: React.FC = () => {
        return (
          <div className="space-y-4 animate-pulse">
            {/* Title skeleton */}
            <div className="h-8 w-48 bg-gray-200 rounded"></div>
            
            {/* Account list skeleton */}
            <div className="space-y-2 mt-4 border rounded-lg overflow-hidden">
              {[1, 2, 3].map((_, index) => (
                <div 
                  key={index}
                  className={`flex items-center space-x-3 p-4 ${index !== 0 ? 'border-t' : ''}`}
                >
                  {/* Radio button skeleton */}
                  <div className="w-4 h-4 rounded-full bg-gray-200"></div>
                  
                  {/* Account info skeleton */}
                  <div className="flex-1">
                    <div className="flex items-center space-x-2">
                      {/* Icon skeleton */}
                      <div className="w-6 h-6 rounded bg-gray-200"></div>
                      {/* Account name skeleton */}
                      <div className="h-5 w-48 bg-gray-200 rounded"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
      
            {/* Button skeleton */}
            <div className="h-10 w-full bg-gray-200 rounded mt-4"></div>
          </div>
        );
      };

      const OverviewSkeleton = () => (
        <div className="space-y-6">
          {/* Analytics Cards Row */}
          <div className="grid grid-cols-4 gap-4">
            {[1, 2, 3, 4].map((i) => (
              <Card key={i} className="p-4">
                <div className="h-4 w-24 bg-gray-200 rounded animate-pulse mb-2" />
                <div className="h-6 w-16 bg-gray-300 rounded animate-pulse" />
              </Card>
            ))}
          </div>
    
          {/* Ad Preview and Search Results */}
          <div className="grid grid-cols-2 gap-6">
            <Card className="p-6">
              <div className="h-6 w-32 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="space-y-3">
                <div className="h-4 w-full bg-gray-200 rounded animate-pulse" />
                <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
                <div className="h-4 w-5/6 bg-gray-200 rounded animate-pulse" />
              </div>
            </Card>
            <Card className="p-6">
              <div className="h-6 w-32 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="space-y-3">
                <div className="h-4 w-full bg-gray-200 rounded animate-pulse" />
                <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse" />
              </div>
            </Card>
          </div>
        </div>
      );
    
      const KeywordsSkeleton = () => (
        <div className="space-y-6">
          <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
          <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse" />
          
          <Card>
            <CardContent className="p-6">
              <div className="h-6 w-40 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="h-10 w-full bg-gray-100 rounded animate-pulse mb-4" />
              <div className="flex flex-wrap gap-2">
                {[1, 2, 3, 4].map((i) => (
                  <div key={i} className="h-8 w-24 bg-gray-200 rounded-full animate-pulse" />
                ))}
              </div>
            </CardContent>
          </Card>
    
          <Card>
            <CardContent className="p-6">
              <div className="h-6 w-40 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="h-10 w-full bg-gray-100 rounded animate-pulse mb-4" />
              <div className="flex flex-wrap gap-2">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="h-8 w-24 bg-gray-200 rounded-full animate-pulse" />
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      );
    
      const BudgetSkeleton = () => (
        <div className="space-y-6">
          <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
          <Card>
            <CardContent className="p-6 space-y-4">
              <div className="h-6 w-40 bg-gray-200 rounded animate-pulse" />
              <div className="h-12 w-48 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
              <div className="h-10 w-full max-w-md bg-gray-100 rounded animate-pulse" />
            </CardContent>
          </Card>
        </div>
      );
    
      const AdContentSkeleton = () => (
        <div className="space-y-6">
          <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
          <Card>
            <CardContent className="p-6 space-y-4">
              <div className="h-6 w-40 bg-gray-200 rounded animate-pulse" />
              {[1, 2, 3].map((i) => (
                <div key={i} className="space-y-2">
                  <div className="h-4 w-24 bg-gray-200 rounded animate-pulse" />
                  <div className="h-10 w-full bg-gray-100 rounded animate-pulse" />
                </div>
              ))}
            </CardContent>
          </Card>
        </div>
      );
    
      const LocationTargetingSkeleton = () => (
        <div className="space-y-6">
          <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
          <Card>
            <CardContent className="p-6">
              <div className="h-6 w-40 bg-gray-200 rounded animate-pulse mb-4" />
              <div className="h-64 w-full bg-gray-100 rounded animate-pulse mb-4" />
              <div className="space-y-2">
                {[1, 2, 3].map((i) => (
                  <div key={i} className="h-8 w-full bg-gray-200 rounded animate-pulse" />
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      );
    
      const SettingsSkeleton = () => (
        <div className="space-y-6">
          <div className="h-8 w-32 bg-gray-200 rounded animate-pulse" />
          <div className="grid gap-6">
            {[1, 2].map((i) => (
              <Card key={i}>
                <CardContent className="p-6">
                  <div className="h-6 w-40 bg-gray-200 rounded animate-pulse mb-4" />
                  <div className="space-y-4">
                    {[1, 2].map((j) => (
                      <div key={j} className="flex items-center space-x-4">
                        <div className="h-5 w-5 bg-gray-200 rounded animate-pulse" />
                        <div className="h-4 w-48 bg-gray-200 rounded animate-pulse" />
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      );

  const skeletonMap: Record<NavigationItem, JSX.Element> = {
    overview: <OverviewSkeleton />,
    keywords: <KeywordsSkeleton />,
    budget: <BudgetSkeleton />,
    adContent: <AdContentSkeleton />,
    locationTargeting: <LocationTargetingSkeleton />,
    settings: <SettingsSkeleton />
  };

  return (
    <div className="flex h-full">
      <div className="flex-1 p-8">
        {skeletonMap[selectedNav]}
      </div>
    </div>
  );
};

export default PageSkeletons;

export const GoogleAdsAccountSkeleton: React.FC = () => {
  return (
    <div className="space-y-4 animate-pulse">
      {/* Title skeleton */}
      <div className="h-8 w-48 bg-gray-200 rounded"></div>
      
      {/* Account list skeleton */}
      <div className="space-y-2 mt-4 border rounded-lg overflow-hidden">
        {[1, 2, 3].map((_, index) => (
          <div 
            key={index}
            className={`flex items-center space-x-3 p-4 ${index !== 0 ? 'border-t' : ''}`}
          >
            {/* Radio button skeleton */}
            <div className="w-4 h-4 rounded-full bg-gray-200"></div>
            
            {/* Account info skeleton */}
            <div className="flex-1">
              <div className="flex items-center space-x-2">
                {/* Icon skeleton */}
                <div className="w-6 h-6 rounded bg-gray-200"></div>
                {/* Account name skeleton */}
                <div className="h-5 w-48 bg-gray-200 rounded"></div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Button skeleton */}
      <div className="h-10 w-full bg-gray-200 rounded mt-4"></div>
    </div>
  );
};