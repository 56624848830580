import { Session } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../utils/supabase";
import { toast } from 'react-toastify';
import axios from "axios";
import { baseUrl } from "@/services/api-config";

const PRODUCTION_URL = 'https://app.trymultiply.com';
const DEVELOPMENT_URL = 'http://localhost:8000';

interface UserStatus {
  is_onboarded: boolean;
  account_linked: boolean;
  oauth2_linked: boolean;
}

interface AuthContextType {
  session: Session | null | undefined;
  token: string | null | undefined;
  signInForOnboarding: () => Promise<void>;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshSession: () => Promise<Session | null>;
  isAuthenticated: boolean;
  userStatus?: UserStatus;
  getUserStatus: () => Promise<UserStatus | undefined>;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  token: null,
  signInForOnboarding: async () => { },
  signIn: async () => { },
  signOut: async () => { },
  refreshSession: async () => null,
  isAuthenticated: false,
  userStatus: undefined,
  getUserStatus: async () => undefined,
});

const buildRedirectUrl = () => {
  const BASE_URL = 'https://app.trymultiply.com'; 
  return `${BASE_URL}/onboarding/link-google-account?step=loading`;
};

const getRedirectUrl = (path: string) => {
  return `https://app.trymultiply.com/account/auth/callback`; 
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userStatus, setUserStatus] = useState<UserStatus>();
  const navigate = useNavigate();

  const saveProviderTokens = async (accessToken: string, providerToken: string, providerRefreshToken: string) => {
    try {
      await axios.post(`${baseUrl}/signIn`, {
        provider_access_token: providerToken,
        provider_refresh_token: providerRefreshToken
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      console.error('Error saving provider tokens:', error);
      throw error;
    }
  };

  const handleSignOut = useCallback(async (message?: string) => {
    console.log('Starting sign out process:', { message });
    
    try {
      // First call your backend signOut endpoint if we have a session
      if (session?.access_token) {
        await axios.post(`${baseUrl}/signOut`, {}, {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            'Content-Type': 'application/json',
          }
        });
      }
  
      // Then cleanup local state
      setSession(null);
      setIsAuthenticated(false);
      setUserStatus(undefined);
  
      // Create a promise that will timeout after 2 seconds
      const signOutPromise = supabase.auth.signOut();
      const timeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error('Sign out timed out')), 2000)
      );
  
      await Promise.race([signOutPromise, timeoutPromise])
        .catch(error => {
          console.warn('Sign out did not complete in time:', error);
        });
  
      if (message) {
        toast.error(message);
      }

      navigate('/');
    } catch (error) {
      console.error('Sign out error:', error);
      navigate('/');
    }
  }, [navigate, session]);

  const validateSession = useCallback((currentSession: Session | null): boolean => {
    if (!currentSession?.access_token) {
      return false;
    }

    try {
      const [header, payload, signature] = currentSession.access_token.split('.');
      if (!header || !payload || !signature) {
        return false;
      }

      const tokenData = JSON.parse(atob(payload));
      const expirationTime = tokenData.exp * 1000;
      const currentTime = Date.now();

      if (currentTime >= expirationTime || !tokenData.sub) {
        return false;
      }

      return true;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }, []);

  const refreshSession = useCallback(async (): Promise<Session | null> => {
    try {
      // First try to get the current session from Supabase
      const { data: { session: currentSession } } = await supabase.auth.getSession();

      console.log(`Refreshing Session: [${currentSession?.refresh_token}]`);
      
      if (!currentSession?.refresh_token) {
        console.warn('No refresh token in current session');
        await handleSignOut('Session expired. Please sign in again.');
        return null;
      }
  
      // Attempt to refresh using the current session's refresh token
      const { data, error } = await supabase.auth.refreshSession({
        refresh_token: currentSession.refresh_token
      });
  
      if (error || !data.session) {
        console.error('Refresh error:', error);
        console.error(`Printing Session: [${JSON.stringify(data.session)}]`);
        await handleSignOut('Your session has expired. Please sign in again.');
        return null;
      }

      setSession(data.session);
      setIsAuthenticated(true);

      console.log(`SUCCESSFULLY REFRESHED: [${JSON.stringify(data.session)}]`);
      
      return data.session;
    } catch (error) {
      console.error('Session refresh error:', error);
      await handleSignOut('Unable to refresh session. Please sign in again.');
      return null;
    }
  }, [handleSignOut]);

  const getUserStatus = useCallback(async (): Promise<UserStatus | undefined> => {
    console.log("Fetching User Status");
 
    try {
      
      // Get current session if needed
      let currentSession = session;
      if (!currentSession?.access_token) {
        const refreshResult = await refreshSession();
        if (!refreshResult?.access_token) {
          throw new Error('No valid session available');
        }
        currentSession = refreshResult;
      }
  
      const headers = {
        Authorization: `Bearer ${currentSession.access_token}`,
        'Content-Type': 'application/json',
      };
  
      const resp = await axios.get(`${baseUrl}/isOnboarded`, { headers });
      console.log(`Successfully fetched response: [${JSON.stringify(resp.data)}]`);
      return resp.data;
    } catch (error) {
      console.error('Error fetching user status:', error);
      return undefined;
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        
        if (!error && currentSession && validateSession(currentSession)) {
          setSession(currentSession);
          setIsAuthenticated(true);
          
          const status = await getUserStatus();
          if (status) {
            setUserStatus(status);
          }
        } else {
          console.log(`Error with session: [${JSON.stringify(currentSession)}] error: [${JSON.stringify(error)}]`);
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    init();
  }, [validateSession, getUserStatus]);

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, currentSession) => {
        console.log(`Auth state: [${event}] current: [${JSON.stringify(currentSession)}]`)
        console.log(`CURRENT SESSION: [${JSON.stringify(session)}]`);

        if (event === 'INITIAL_SESSION') {
          if (currentSession) {
            setSession(currentSession);
            setIsAuthenticated(true);
            const status = await getUserStatus();
            setUserStatus(status);
          }
          setLoading(false);
          return;
        }

        if (event === 'SIGNED_OUT') {
          setSession(null);
          setIsAuthenticated(false);
          setUserStatus(undefined);
          navigate('/');
          return;
        }

        if (event === 'SIGNED_IN') {
          if (currentSession && currentSession.provider_token && currentSession.access_token && currentSession.provider_refresh_token) {
            await saveProviderTokens(
              currentSession.access_token,
              currentSession.provider_token,
              currentSession.provider_refresh_token!
            );
            setSession(currentSession);
          } else {
            console.log("Handling signOut in SIGNED_IN state");
            await handleSignOut();
          }                
        } else if (event === 'TOKEN_REFRESHED' && currentSession) {
          setSession(currentSession);
        }
        setIsAuthenticated(true);
      }
    );

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, [validateSession, loading, navigate]);

  const signInForOnboarding = async () => {
    const redirectTo = buildRedirectUrl();

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectTo,
          scopes: [
            'https://www.googleapis.com/auth/adwords',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
          ].join(' '),
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const signIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: getRedirectUrl(''),
          scopes: [
            'https://www.googleapis.com/auth/adwords',
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile'
          ].join(' '),
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const value = {
    session,
    token: session?.access_token ?? null,
    signIn,
    signOut: () => handleSignOut(),
    signInForOnboarding,
    isAuthenticated,
    refreshSession,
    userStatus,
    getUserStatus
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};