import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/utils/AuthProvider';

const AuthCallback = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { getUserStatus, session, refreshSession, signOut } = useAuth();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        // Get final_redirect from URL params
        const params = new URLSearchParams(window.location.search);

        let currentSession = session;
        if (!currentSession?.access_token) {
          currentSession = await refreshSession();
        }

        if (!currentSession?.access_token) {
            console.error('No session available');
            navigate('/login');
            return;
          }

        console.log("FUCK");
        const userStatus = await getUserStatus();
        console.log(`FUCK: [${JSON.stringify(userStatus)}]`)

        if (!userStatus) {
            navigate('/')
        }

        if (!userStatus?.oauth2_linked) {
            navigate('/onboarding/link-google-account?step=signin')
        } else if (userStatus?.is_onboarded) {
            navigate('/manage-campaign')
        } else if (!userStatus?.account_linked) {
            navigate('/onboarding/link-google-account?step=loading')
        } else if (!userStatus?.is_onboarded) {
            navigate('/onboarding?step=4')
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };
    checkUserStatus();
  }, []);

  if (isLoading) {
    return <div>Processing authentication...</div>;
  }

  return null;
};

export default AuthCallback;