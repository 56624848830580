import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseUrl } from '@/services/api-config';
import { useAuth } from '@/utils/AuthProvider';
import { StepStatus } from '@/utils/Interfaces';
import axios from 'axios';

interface OnboardingPaymentInfo {
  setup_intent_id: string;
  payment_id: string;
  budget: number;
}

interface CampaignSummaryProps {
  onStepComplete?: (status: StepStatus) => void;
  onClose?: () => void;
}

const CampaignSummaryOverlay: React.FC<CampaignSummaryProps> = ({ onStepComplete, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [conversionData, setConversionData] = useState<any>(null);
  const [locationData, setLocationData] = useState<any>(null);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [campaignId] = useState(localStorage.getItem('campaignId'));
  const { session, refreshSession } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Load all data from localStorage
    const conversion = localStorage.getItem('conversion_profile');
    const location = localStorage.getItem('onboarding_location');
    const payment = localStorage.getItem('payment_setup_data');

    if (conversion) setConversionData(JSON.parse(conversion));
    if (location) setLocationData(JSON.parse(location));
    if (payment) setPaymentData(JSON.parse(payment));

    // Validate that we have all required data
    const isComplete = conversion && location && payment && campaignId;
    
    if (onStepComplete) {
      onStepComplete({
        isComplete: !!isComplete,
        message: !isComplete ? 'Missing required campaign information' : undefined
      });
    }
  }, [campaignId, onStepComplete]);

  const handleCreateCampaign = async () => {
    setIsSubmitting(true);

    try {
      await refreshSession();

      if (!paymentData?.setupIntentId || !paymentData?.paymentMethodId) {
        throw new Error('Incomplete payment information');
      }

      const paymentInfo: OnboardingPaymentInfo = {
        setup_intent_id: paymentData.setupIntentId,
        payment_id: paymentData.paymentMethodId,
        budget: Math.floor(paymentData.dailyBudget)
      };

      const body = {
        provider_api_token: session!.provider_token,
        provider_refresh_token: session!.provider_refresh_token,
        campaign_id: campaignId,
        payment_info: paymentInfo,
        location: locationData,
        profile: conversionData.conversionType === 'leads' 
          ? {
              monthly_leads: parseInt(conversionData.monthly),
              customer_spend: parseFloat(conversionData.average),
              estimated_profit: parseFloat(conversionData.profit),
              conversion_rate: conversionData.conversionRate?.[0],
            }
          : {
              monthly_sales: parseInt(conversionData.monthly),
              aov: parseFloat(conversionData.average),
              est_profit: parseFloat(conversionData.profit),
            }
      };

      const headers = {
        Authorization: `Bearer ${session?.access_token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(`${baseUrl}/googleads/campaign/create`, body, { headers });
      
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Clean up localStorage
      localStorage.removeItem('payment_setup_data');
      localStorage.removeItem('onboarding_location');
      localStorage.removeItem('conversion_profile');
      localStorage.removeItem('campaignId');
      
      toast.success('Campaign created successfully!');
      
      // Close the overlay and navigate
      if (onClose) {
        onClose();
      }
      navigate('/manage-campaign');
      
    } catch (error) {
      console.error('Error creating campaign:', error);
      toast.error('Failed to create campaign. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!conversionData || !locationData || !paymentData) {
    return (
      <Card className="w-full max-w-2xl mx-auto">
        <CardContent className="p-6">
          <div className="text-center text-red-600">
            Missing required campaign information. Please complete all previous steps.
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <h2 className="text-2xl font-bold">Campaign Summary</h2>
        <p className="text-gray-600">Review your campaign details before creation</p>
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Budget Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Budget</h3>
          <p className="text-gray-600">Daily budget: ${paymentData.dailyBudget}</p>
        </div>

        {/* Location Information */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Location Targeting</h3>
          <p className="text-gray-600">
            {locationData.formatted_address || 'Location information not available'}
          </p>
        </div>

        {/* Conversion Profile */}
        <div className="border-b pb-4">
          <h3 className="font-semibold mb-2">Conversion Profile</h3>
          <div className="space-y-2">
            <p className="text-gray-600">Type: {conversionData.conversionType}</p>
            <p className="text-gray-600">
              {conversionData.conversionType === 'leads' ? 'Monthly leads: ' : 'Monthly sales: '}
              {conversionData.monthly}
            </p>
            <p className="text-gray-600">
              {conversionData.conversionType === 'leads' ? 'Average customer spend: ' : 'Average order value: '}
              ${conversionData.average}
            </p>
            <p className="text-gray-600">Estimated profit: ${conversionData.profit}</p>
            {conversionData.conversionType === 'leads' && (
              <p className="text-gray-600">Conversion rate: {conversionData.conversionRate[0]}%</p>
            )}
          </div>
        </div>

        <Button 
          onClick={handleCreateCampaign}
          className="w-full"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Creating Campaign...' : 'Confirm & Create Campaign'}
        </Button>
      </CardContent>
    </Card>
  );
};

export default CampaignSummaryOverlay;