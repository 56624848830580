

const TermsOfService = () => {
  return (
    <div className="prose max-w-none">
      <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>
      <p className="text-sm text-gray-600 mb-4"><strong>Effective Date:</strong> 10/21/2024</p>
      
      <p className="mb-4">
        These Terms of Use ("Terms") govern your access to and use of the Multiply website (https://trymultiply.com/) and services ("Services"). 
        By using our Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the Services.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">1. Description of Services</h2>
      <p className="mb-4">
        Multiply is an AI-powered digital marketing tool that helps users manage their advertising campaigns. 
        Our Services include the management of Google Ads campaigns, for which users are responsible for setting up a Google Ads account and billing directly with Google.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">2. Eligibility</h2>
      <p className="mb-4">
        You must be at least 18 years old to create an account and use our Services. 
        By creating an account, you confirm that you meet this age requirement.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">3. Account Registration</h2>
      <p className="mb-4">To use Multiply, users must:</p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Register an account on our platform.</li>
        <li className="mb-2">Connect their existing Google Ads account, which includes having billing set up directly with Google. 
        You are solely responsible for the security of your login credentials and account information.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">4. Fees and Payments</h2>
      <p className="mb-2">
        Multiply charges a 10% fee on the total ad spend managed through our platform. 
        Google charges you separately for the ad spend itself, for which you are responsible for setting up payment with Google.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">The 10% management fee is charged weekly through <strong>Stripe</strong>. 
        During account setup, you will be required to provide a valid payment method.</li>
        <li className="mb-2">You authorize us to charge this 10% fee directly to your provided payment method.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">5. Intellectual Property</h2>
      <p className="mb-4">
        Multiply retains all intellectual property rights in any content, tools, or resources generated as part of the ad management process. 
        You may not claim ownership of any of the IP generated by Multiply's Services.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">6. Termination of Accounts</h2>
      <p className="mb-4">
        Multiply reserves the right to terminate your account at any time and for any reason. 
        If you wish to delete your account, you must contact us at <strong>admin@trymultiply.com</strong>. 
        Upon account deletion, all personal data will be permanently removed in accordance with our data retention policies.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">7. Limitation of Liability</h2>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Multiply accepts no responsibility for service interruptions, data loss, or third-party activities.</li>
        <li className="mb-2">Multiply does not guarantee the performance of any ad campaigns managed through our Services and is not liable for any financial outcomes related to ad performance.</li>
        <li className="mb-2">In no event will Multiply be liable for any direct, indirect, incidental, or consequential damages, except as limited to the amount paid to Multiply for its management services, not including Google ad spend.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">8. Dispute Resolution</h2>
      <p className="mb-2">
        All disputes arising out of or relating to these Terms will be resolved through binding arbitration in New York County, New York State, United States.
      </p>
      <ul className="list-disc ml-6 mb-4">
        <li className="mb-2">Users waive the right to participate in any class-action lawsuits.</li>
        <li className="mb-2">Liability is limited to the management fees paid to Multiply. Multiply will not cover arbitration costs.</li>
      </ul>

      <h2 className="text-xl font-bold mt-6 mb-2">9. Governing Law</h2>
      <p className="mb-4">
        These Terms will be governed by and construed in accordance with the laws of New York State, United States, without regard to its conflict of law provisions.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">10. Third-Party Links</h2>
      <p className="mb-4">
        Our website may contain links to third-party sites. Multiply is not responsible for the content, privacy policies, or practices of these third-party websites. 
        Use of these websites is at your own risk.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">11. Changes to Terms of Use</h2>
      <p className="mb-4">
        Multiply reserves the right to modify or update these Terms at any time. 
        You will be notified of any changes via email. 
        Your continued use of the Services after such modifications constitutes your acceptance of the updated Terms.
      </p>

      <h2 className="text-xl font-bold mt-6 mb-2">12. Contact Us</h2>
      <p className="mb-4">
        For any questions or concerns regarding these Terms, please contact us at <strong>admin@trymultiply.com</strong>.
      </p>
    </div>
  );
};

export default TermsOfService;