export const LOCATION_KEY = 'location_data';
export const DOMAIN_KEY = 'domain';

interface StepRequirements {
    key: string;
    required: boolean;
}

const ONBOARDING_STEP_DEPENDENCIES: StepRequirements[][] = [
    // preview
    [
        {key: DOMAIN_KEY, required: true},
    ],
    [
        {key: DOMAIN_KEY, required: true}
    ]
];

export const ACTIVE_CAMPAIGN_ID = 'activeCampaignId';