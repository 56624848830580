import React, { useEffect, useState, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Card, CardContent } from '@/components/ui/card';
import { useAuth } from '@/utils/AuthProvider';
import GoogleButton from 'react-google-button';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { Button } from '@/components/ui/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { GoogleAdsAccountSkeleton } from '@/components/ui/skeleton-loader';

const GoogleAdsConnection: React.FC = () => {
  const { session, signInForOnboarding, isAuthenticated } = useAuth();
  const [step, setStep] = useState<'signin' | 'loading' | 'select' | 'connected' | undefined>();
  const [accounts, setAccounts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [isFetchingAccounts, setIsFetchingAccounts] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchGoogleAdsAccounts = useCallback(async () => {
    if (!session?.access_token) {
      console.error("No tokens set in fetchGoogleAdsAccounts");
      return;
    }

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
    };

    setError(null);
    setIsFetchingAccounts(true);

    try {
      const response = await axios.get(`${baseUrl}/account/get-google-ads-accounts`, { headers });
      setAccounts(response.data?.linkable_accounts || []);
      setStep('select');
    } catch (err) {
      console.error('Error fetching Google Ads accounts:', err);
      setError('Failed to fetch Google Ads accounts. Please try again.');
      setStep('signin');
    } finally {
      setIsFetchingAccounts(false);
    }
  }, [session?.provider_token, session?.provider_refresh_token]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentStep = queryParams.get('step');
    console.log(`Current Step: [${currentStep}]`);

    const pathParts = location.pathname.split('/').filter(Boolean);
    const suffix = pathParts[pathParts.length - 1];

    if (suffix !== 'link-google-account') {
      setStep('signin');
      setError(null);
      return;
    }

    if (currentStep === 'loading') {
      setError(null);
      if (isAuthenticated) {
        setStep('loading');
        fetchGoogleAdsAccounts()
      } else {
        setStep('signin');
      }
    }
  }, [isAuthenticated, location.search, fetchGoogleAdsAccounts]);

  const handleLinkAdsAccount = async () => {
    if (!selectedAccount) {
      setError("Please select an account and ensure you're logged in");
      return;
    }

    if (!session) {
      setError("No Session found");
      return;
    }

    const domain = localStorage.getItem('domain');
    if (!domain) {
      setError("No domain set for application");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const headers = {
        Authorization: `Bearer ${session.access_token}`,
      };

      const response = await axios.post(
        `${baseUrl}/account/link-google-ads-account`,
        {
          domain: domain,
          account_id: selectedAccount,
        },
        { headers }
      );

      localStorage.setItem('campaignId', response.data.campaign_id);
      setStep('connected');
    } catch (error) {
      console.error('Error linking Google Ads account:', error);
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || 'Failed to link Google Ads account');
      } else {
        setError('Failed to link Google Ads account');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAccountSelection = (accountId: string, billingApproved: boolean) => {
    if (billingApproved) {
      setSelectedAccount(accountId);
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardContent className="p-6">
        {step === 'signin' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Connect Your Google Ads Account</h2>
            <p className="text-gray-600 mb-4">
              Sign in with your Google account to connect Multiply with your Google Ads.
            </p>
            <GoogleButton onClick={signInForOnboarding}>Login with Google</GoogleButton>
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
        )}

        {(step === 'loading' || isFetchingAccounts) && (
          <GoogleAdsAccountSkeleton />
        )}

        {step === 'select' && (
          <div>
            <h2 className="text-2xl font-bold mb-4">Select Google Ads Account</h2>
            <div className="space-y-2 mt-4 border rounded-lg overflow-hidden">
              {accounts.map((account, index) => (
                <label
                  key={account.id}
                  className={`flex items-center space-x-3 p-4 min-h-16 cursor-pointer ${
                    !account.billing_approved ? 'opacity-50 bg-gray-100' : 'hover:bg-gray-50'
                  } ${selectedAccount === account.id ? 'bg-blue-50' : ''} ${
                    index !== 0 ? 'border-t' : ''
                  }`}
                >
                  <input
                    type="radio"
                    name="googleAdsAccount"
                    value={account.id}
                    checked={selectedAccount === account.id}
                    onChange={() => handleAccountSelection(account.id, account.billing_approved)}
                    disabled={!account.billing_approved}
                    className="form-radio text-blue-600"
                  />
                  <div className="flex-1">
                    <div className="flex items-center space-x-2">
                      <img
                        src="/google-ads.svg"
                        alt="Google Ads"
                        className="w-6 h-6 flex-shrink-0"
                        onError={(e) => {
                            console.log("Image failed to load:", e);
                            console.log("Attempted URL:", e.currentTarget.src);
                            console.log("Base URL:", window.location.origin);
                          }
                        }
                      />
                      <span className="text-sm font-medium">{account.descriptive_name}</span>
                    </div>
                    {!account.billing_approved && (
                      <span className="text-sm text-red-500 block mt-1">
                        Billing not approved
                      </span>
                    )}
                  </div>
                </label>
              ))}
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <Button
              onClick={handleLinkAdsAccount}
              disabled={!selectedAccount || loading}
              className="mt-4 w-full bg-blue-600 text-white hover:bg-blue-700"
            >
              {loading ? 'Linking...' : 'Link Account'}
            </Button>
          </div>
        )}

        {step === 'connected' && (
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              className="w-20 h-20 bg-gray-800 rounded-full mx-auto mb-4 flex items-center justify-center"
            >
              <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </motion.div>
            <h2 className="text-2xl font-bold mb-2">Connected!</h2>
            <Button
              onClick={() => navigate('/onboarding?step=4')}
              className="mt-4 w-full bg-blue-600 text-white hover:bg-blue-700"
            >
              Continue to Conversion Profile
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default GoogleAdsConnection;